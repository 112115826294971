import { getLocaleFromNavigator } from 'svelte-i18n';

import SerbiaIcon from '$lib/assets/icons/serbia.svg';
import UKIcon from '$lib/assets/icons/uk.svg';

export interface Language {
  code: string;
  displayName: string;
  icon: string;
}

export const sr_code = 'sr';
export const en_code = 'en';

export const languages: Language[] = [
  {
    code: sr_code,
    displayName: 'Srpski',
    icon: SerbiaIcon
  },
  {
    code: en_code,
    displayName: 'English',
    icon: UKIcon
  }
];

export const DEFAULT_LANGUAGE = 'en';

export const getLanguageFromLocale = (): string => {
  const locale = getLocaleFromNavigator();

  if (!locale?.startsWith(sr_code)) {
    return DEFAULT_LANGUAGE;
  } else {
    return sr_code;
  }
};
